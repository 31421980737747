import React from 'react'

import { useLogin } from '../hooks/useLogin'
import toast, { Toaster } from 'react-hot-toast'

const Login = () => {
  const { error, login } = useLogin()

  const handleSubmit = e => {
    e.preventDefault()
    const email = e.target[0].value
    const password = e.target[1].value

    login(email, password)

    if (error) {
      toast.error(error.message)
    }
  }

  // return (
  //   <section className='flex justify-center items-center w-full h-full'>
  //     <div className='bg-amber-500 text-gray-800 p-8 flex flex-col gap-2 rounded-2xl mt-64'>
  //       <h1 className='text-center text-2xl font-medium p-4'>
  //         Zaloguj się do panelu administracyjnego
  //       </h1>
  //       <form className='flex flex-col gap-2 justify-center items-center text-center'>
  //         <label className='text-bold text-xl'>Email: </label>
  //         <input
  //           placeholder='jan.kowalski@example.com'
  //           className='p-2 rounded-full border-2 border-amber-600 shadow-md font-bold text-center'
  //         />

  //         <label className='text-bold text-xl'>Password: </label>
  //         <input
  //           placeholder='********'
  //           className='p-2 rounded-full border-2 border-amber-600 shadow-md text-center font-bold'
  //           type='password'
  //         />
  //         <button className='bg-gray-900 py-2 px-4 text-amber-600 font-bold uppercase rounded-full hover:bg-gray-900/80 flex justify-center items-center'>
  //           Zaloguj
  //         </button>
  //       </form>
  //     </div>
  //   </section>
  // )

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <Toaster />
      <div className='flex justify-center items-center w-fit h-fit'>
        <div className='bg-slate-700 w-full h-full flex justify-center items-center p-12 rounded-md'>
          <div className='flex flex-col justify-center items-center'>
            <span className='text-2xl font-medium uppercase '>
              Mlodemalzenstwa.pl
            </span>
            <span className='text-xl my-2 text-slate-50'>Logowanie</span>
            <form
              className='flex flex-col justify-center items-center p-4'
              onSubmit={handleSubmit}
            >
              <input
                type='email'
                placeholder='test@example.com'
                className='p-4 m-2 text-black placeholder:text-slate-300 bg-slate-100 rounded-md'
              />
              <input
                type='password'
                placeholder='password'
                className='p-4 m-2 text-black placeholder:text-slate-300 bg-slate-100 rounded-md'
              />
              <button className='px-4 py-2 bg-slate-900 hover:bg-slate-800 rounded-md shadow-lg'>
                Zaloguj
              </button>
            </form>

            <p className='text-center'>
              Aby powrócić do strony głównej
              <a
                href='https://mlodemalzenstwa.pl'
                target='_blank'
                rel='noreferrer'
                className='underline mx-2 text-white'
              >
                Kliknij tutaj
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
