import React from 'react'

const ReportListItem = ({ report, number }) => {
  //   const date = report.createdAt.toDate().toDateString()

  const {
    firstNameWomen,
    firstNameMen,
    lastName,
    city,
    email,
    message,
    createdAt
  } = report
  // console.log(report)

  // if (createdAt) {
  //   console.log(createdAt.toDate().toDateString())
  // }

  const Data = new Date(report.createdAt?.toMillis()).toLocaleDateString()
  // console.log(Data)
  const Nowy = Data.toString()

  return (
    <>
      <tr className='group border-b border-[1px] bg-gray-800 border-gray-500 hover:bg-gray-700 group-hover:border-gray-800'>
        <th
          scope='row'
          className='py-4 px-6 font-bold  whitespace-nowrap text-white border-r-[1px] border-gray-700 group-hover:border-gray-800'
        >
          {createdAt && Nowy}
        </th>
        <td className='py-4 px-6 border-x-[1px] border-gray-700 group-hover:border-gray-800'>
          <a className='text-blue-500 underline' href={`mailto:${email}`}>
            {email}
          </a>
        </td>
        <td className='py-4 px-6 border-r-[1px] border-gray-700 group-hover:border-gray-800'>
          {firstNameWomen}
        </td>
        <td className='py-4 px-6 border-r-[1px] border-gray-700 group-hover:border-gray-800'>
          {firstNameMen}
        </td>
        <td className='py-4 px-6 border-r-[1px] border-gray-700 group-hover:border-gray-800'>
          {lastName}
        </td>
        <td className='py-4 px-6 border-r-[1px] border-gray-700 group-hover:border-gray-800'>
          {city}
        </td>
        <td className='py-4 px-6 text-slate-300'>{message}</td>
      </tr>
    </>
  )

  //   return (
  //     <div className='w-1/5 max-h-2/5 bg-slate-800 hover:bg-slate-600 relative'>
  //       <div className='flex flex-col justify-center items-center'>
  //         <p className='m-2'>Imię Małżonki: {firstNameWomen}</p>
  //         <p className='m-2'>Imię Małżonka: {firstNameMen}</p>
  //         <p className='m-2'>Nazwisko: {lastName}</p>
  //         <p className='m-2'>Miasto: {city}</p>
  //         <p className='m-2'>Email: {email}</p>
  //         <p className='m-2 text-center mb-4'>Wiadomość: {message}</p>
  //         <p className='h-fit bg-slate-700 absolute left-0 bottom-0'>
  //           Data Zgłoszenia: {createdAt && createdAt.toDate().toDateString()}
  //         </p>
  //       </div>
  //     </div>
  //   )
}

export default ReportListItem
