import React, { useContext } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from './components/Login'
import MainPage from './components/MainPage'
import NotFound from './components/NotFound'
import { AuthContext } from './context/AuthContext'

const App = () => {
  // const { user } = useAuthContext()

  // const ProtectedRoute = ({ children }) => {
  //   if (!user) {
  //     return <Navigate to='/login' />
  //   }
  // }
  const ctx = useContext(AuthContext)
  // const { userState } = ctx

  const ProtectedRoute = ({ children }) => {
    return ctx.state.user ? children : <Navigate to='/login' />
  }
  // console.log(user)

  // console.log(ctx.state.user)

  return (
    <div className='w-full min-h-screen  bg-slate-900 text-slate-300'>
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route
              index
              element={
                <ProtectedRoute>
                  <MainPage />
                </ProtectedRoute>
              }
            />
            <Route path='login' element={<Login />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
