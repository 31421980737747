import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound () {
  return (
    <div className='pt-64 flex flex-col justify-center items-center'>
      <h1 className='text-4xl text-center '>
        Error 404 | Nie znaleziono takiej strony
      </h1>
      <Link to='/' className='mt-10 underline'>
        Wróc do strony głównej
      </Link>
    </div>
  )
}
