import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { auth } from '../firebase/Config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'

export const useLogin = () => {
  const [error, setError] = useState(null)
  const { dispatch } = useAuthContext()
  const navigate = useNavigate()

  const login = async (email, password) => {
    setError(null)
    await signInWithEmailAndPassword(auth, email, password)
      .then(res => {
        dispatch({ type: 'LOGIN', payload: res.user })
        navigate('/')
      })
      .catch(err => {
        setError(err)
      })
  }

  return {
    login,
    error
  }
}
