import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase/Config'
import { signOut } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
  const { dispatch } = useAuthContext()
  const navigate = useNavigate()

  const logout = async () => {
    await signOut(auth)
      .then(() => {
        dispatch({ type: 'LOGOUT' })
        navigate('/login')
      })
      .catch(err => {
        console.log(err)
      })
  }

  return { logout }
}
