import React, { useState, useEffect } from 'react'
import { db } from '../firebase/Config'
import { collection, getDocs } from 'firebase/firestore'
import ReportListItem from './ReportListItem'
import LoadingIcon from '../assets/icons/LoadingIcon'
import Navbar from './Navbar'

const MainPage = () => {
  const [reports, setReports] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSortedAscending, setIsSortedAscending] = useState(true);


  useEffect(() => {
    const ref = collection(db, 'reports')

    getDocs(ref).then(snapshot => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({ id: doc.id, ...doc.data() })
      })
      results.sort((a, b) => {
        return b.createdAt.seconds - a.createdAt.seconds
      })
      setReports(results)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    })
  }, [])

  const changeSort = () => {
    setIsSortedAscending(prevState => !prevState);
    setReports(prevReports => {
      const newReports = [...prevReports];
      if (isSortedAscending) {
        newReports.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);
      } else {
        newReports.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
      }
      return setReports(newReports)
    });
  };

  if (isLoading) {
    return (
      <div className='h-full w-full flex justify-center'>
        <div className='text-4xl animate-spin mt-96'>
          <LoadingIcon />
        </div>
      </div>
    )
  }

  if (!isLoading) {
    return (
      <>
        <Navbar />
        <div className='w-full h-full flex justify-center'>
          <div className='overflow-x-auto m-6'>
            <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
              <thead className='text-xs  uppercase  bg-gray-700 text-gray-400 text-center'>
                <tr>
                  <th scope='col' className='py-3 px-6 cursor-pointer selection:bg-none hover:text-white' onClick={changeSort}>
                    Data Zgłosznia
                  </th>
                  <th scope='col' className='py-3 px-6'>
                    Email
                  </th>
                  <th scope='col' className='py-3 px-6'>
                    Imię Małżonki
                  </th>
                  <th scope='col' className='py-3 px-6'>
                    Imię Małżonka
                  </th>
                  <th scope='col' className='py-3 px-6'>
                    Nazwisko
                  </th>
                  <th scope='col' className='py-3 px-6'>
                    Miasto
                  </th>
                  <th scope='col' className='py-3 px-6'>
                    Wiadomość
                  </th>
                </tr>
              </thead>
              <tbody>
                {reports &&
                  reports.map(report => (
                    <ReportListItem report={report} key={report.id} />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className='flex flex-row flex-wrap gap-4 p-2 container'>
                
              </div> */}
      </>
    )
  }
}

export default MainPage
