import React from 'react'

function LoadingIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      className='icon icon-tabler icon-tabler-3d-cube-sphere'
      viewBox='0 0 24 24'
    >
      <path stroke='none' d='M0 0h24v24H0z'></path>
      <path d='M6 17.6l-2-1.1V14M4 10V7.5l2-1.1M10 4.1L12 3l2 1.1M18 6.4l2 1.1V10M20 14v2.5l-2 1.12M14 19.9L12 21l-2-1.1'></path>
      <path d='M12 12L14 10.9'></path>
      <path d='M18 8.6L20 7.5'></path>
      <path d='M12 12L12 14.5'></path>
      <path d='M12 18.5L12 21'></path>
      <path d='M12 12l-2-1.12'></path>
      <path d='M6 8.6L4 7.5'></path>
    </svg>
  )
}

export default LoadingIcon
