import { createContext, useReducer, useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload }
    case 'LOGOUT':
      return { ...state, user: null }
    case 'AUTH_IS_READY':
      return { user: action.payload, authIsReady: true }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false
  })
  const [userState, setUserState] = useState({})

  const getAuthState = getAuth()

  useEffect(() => {
    const unsub = onAuthStateChanged(getAuthState, (auth, user) => {
      dispatch({ type: 'AUTH_IS_READY', payload: user })
      setUserState(user)
      unsub()
    })
  }, [getAuthState])

  // console.log('AuthContext state: ', state)
  return (
    <AuthContext.Provider value={{ state, dispatch, userState }}>
      {children}
    </AuthContext.Provider>
  )
}
